import React from "react";
import InfoCard from "./InfoCard";
import { useSelector } from "react-redux";
import { selectTotals } from "../../redux/totalsSlice";
import Grid from "@material-ui/core/Grid";
import "./Info.css";

const totals = [
  { label: "Total weight (MT)", field: "total_catch_weight" },
  { label: "Total value (USD)", field: "total_catch_value" },
  { label: "Total #trips", field: "total_trips" },
  { label: "Fishers Recorded", field: "fishers_recorded" },
  { label: "Total female fishers", field: "total_female_fishers" },
  { label: "Total buyers", field: "total_buyers" },
];

const Info = () => {
  const totalsData = useSelector(selectTotals);

  return (
    <div className={"InfoCardsContainer"}>
      <Grid container spacing={1}>
        {totals.map(({ label, field }, index) => (
          <Grid key={field} item xs={6} sm={4} md={2}>
            <InfoCard key={index} value={totalsData[field]} label={label} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
export default Info;
