import {
  getAvgCatchValueCPUE,
  getCatchCompositionTop10,
  getTotalCatchPerMonth,
  getMedianLength
} from "../services/DataWorldAPI";
import createChartSlice from "./genericChartSlice";
import { createSelector } from "@reduxjs/toolkit";
import { selectDateRangeAsFilter } from "./dateRangeFilterSlice";
import { selectMASelectedIDsAsFilter } from "./filterSlice";
import {
  selectSelectedFishBaseIDsAsFilter,
  selectSpeciesGrouping,
  selectSpeciesRange,
} from "./catchCompositionTop10SettingsSlice";
import { selectMAIDsAndDateRangeFilters } from "./selectors";

// ma_ids AS STRING  = '100,200,300....'
// focal_sp AS INTEGER = 0   -- 0 = All Species, 1 = Focal Species
// group_by AS STRING = "species"  --'species' or  'family'
// start AS DATE = DATE "2000-01-01"
// end AS DATE = DATE "2222-12-31"
export const selectSpeciesFilters = createSelector(
  [selectSpeciesRange, selectSpeciesGrouping],
  (speciesRange, speciesGrouping) => ({
    focal_sp: speciesRange,
    group_by: speciesGrouping,
  })
);
export const selectCatchCompositionTop10Filters = createSelector(
  [selectMAIDsAndDateRangeFilters, selectSpeciesFilters],
  (selectedMAIDsAndDateRangeFilter, speciesFilters) => ({
    ...selectedMAIDsAndDateRangeFilter,
    ...speciesFilters,
  })
);

// ma_ids AS STRING  = '100,200,300....'
// fishbase_ids AS STRING  = '100,200,300....'
// start AS DATE = DATE "2000-01-01"
// end AS DATE = DATE "2222-12-31"
export const selectChartFilters = createSelector(
  [
    selectMASelectedIDsAsFilter,
    selectSelectedFishBaseIDsAsFilter,
    selectDateRangeAsFilter,
  ],
  (selectedMaIDs, selectedFishBaseIDs, dateRange) => ({
    ...selectedMaIDs,
    ...selectedFishBaseIDs,
    ...dateRange,
  })
);

const fetchAndProcessCatchCompositionTop10 = async (params) => {
  const { data } = await getCatchCompositionTop10(params);
  const newData = data.map((item) => ({
    ...item,
    local_name: item.local_name || "",
    fishbase_ids: item.fishbase_ids.split(",").map((id) => parseInt(id)),
  }));
  return { data: newData };
};
export const {
  reducer: catchCompositionTop10Reducer,
  ...catchCompositionTop10Slice
} = createChartSlice(
  "catchCompositionTop10",
  fetchAndProcessCatchCompositionTop10,
  selectCatchCompositionTop10Filters
);
export const {
  reducer: totalCatchPerMonthReducer,
  ...totalCatchPerMonthSlice
} = createChartSlice(
  "totalCatchPerMonth",
  getTotalCatchPerMonth,
  selectChartFilters
);
export const {
  reducer: avgCatchValueCPUEReducer,
  ...avgCatchValueCPUESlice
} = createChartSlice(
  "avgCatchValueCPUE",
  getAvgCatchValueCPUE,
  selectChartFilters
);
export const {
  reducer: medianLengthReducer,
  ...medianLengthSlice
} = createChartSlice(
  "medianLength",
  getMedianLength,
  selectChartFilters
);

export const selectIsChartsLoading = createSelector(
  [
    catchCompositionTop10Slice.selectIsLoading,
    totalCatchPerMonthSlice.selectIsLoading,
    avgCatchValueCPUESlice.selectIsLoading,
    medianLengthSlice.selectIsLoading,
  ],
  (...args) => args.some((arg) => !!arg)
);
