const SocioEcoData = () => {
  return (
    <div className={"socio-eco-data-container"}>
      Access{" "}
      <a href="https://portal.rare.org/tools-and-data/household-survey-data/">
        Socioeconomic
      </a>{" "}
      and <a href="https://portal.rare.org/data/ecological-data/">Ecological</a>{" "}
      data
    </div>
  );
};

export default SocioEcoData;
