import MultiSelectDropdown from "./MultiSelectDropdown";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  countrySlice,
  lguSlice,
  snuSlice,
  maSlice,
} from "../../redux/filterSlice";

const CountriesFilter = () => {
  const dispatch = useDispatch();
  const countries = useSelector(countrySlice.selectAll);
  const isLoading = useSelector(countrySlice.selectIsLoading);
  const selectedValues = useSelector(countrySlice.selectSelected);
  const onItemSelectionChange = (items, isSelected) => {
    dispatch(countrySlice.setSelected({ items, isSelected }));
    const payload = {
      key: "country_id",
      parents: items,
      isSelected: isSelected,
    };
    dispatch(snuSlice.setSelectedByParent(payload));
    dispatch(lguSlice.setSelectedByParent(payload));
    dispatch(maSlice.setSelectedByParent(payload));
  };

  return (
    <MultiSelectDropdown
      id={"countries"}
      label={"Countries"}
      options={countries}
      selectedValues={selectedValues}
      getOptionSelected={(option, value) =>
        option.country_id === value.country_id
      }
      displayValue={"country_name"}
      onItemSelectionChange={onItemSelectionChange}
      loading={isLoading}
      closeOnSelect={false}
    />
  );
};
export default CountriesFilter;
