import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const TextButton = withStyles(() => ({
  root: {
    fontSize: "18px",
    color: "#5492DA",
    textTransform: "none",
  },
}))(Button);

const CloseFilterButton = ({ onClick }) => (
  <Box textAlign={"right"}>
    <TextButton onClick={onClick}>Close</TextButton>
  </Box>
);

export default CloseFilterButton;
