import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./Map.css";
import MapMarkers from "./MapMarkers";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  topGradient: {
    height: "105px",
    width: "100%",
    position: "absolute",
    top: "0px",
    pointerEvents: "none",
    zIndex: 401,
    background:
      "linear-gradient(180deg, #4A629F 0%, rgba(178, 213, 255, 0) 100%)",
    mixBlendMode: "multiply",
  },
  "@media (min-width: 667px)": {
    topGradient: {
      height: "200px",
    },
  },
});

const Map = () => {
  const IS_TOUCH_DEVICE =
    "ontouchstart" in window || navigator.msMaxTouchPoints;
  const classes = useStyles();
  return (
    <React.Fragment>
      <MapContainer
        className={"MapComponent"}
        // center={[51.505, -0.09]}
        center={[0, 0]}
        zoom={1}
        minZoom={1}
        scrollWheelZoom={true}
        zoomControl={false}
        worldCopyJump={true}
        dragging={!IS_TOUCH_DEVICE}
        tap={!IS_TOUCH_DEVICE}

        // boundsOptions={{
        //   // paddingTopLeft: [30, 30],
        //   // paddingBottomRight: [300, 300],
        //   padding: [30, 30],
        //
        // }}
        // attributionControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://api.mapbox.com/styles/v1/stoyleg/ckog2kkao0o5j1amjvgizilp2/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic3RveWxlZyIsImEiOiJjaWZ5ZXN1YXQwMmh4dDBtN2lidWZpb3AwIn0.h_bxYt64qWi6PIBeJ1bqPA"
        />
        <MapMarkers />
      </MapContainer>
      <div className={classes.topGradient}></div>
    </React.Fragment>
  );
};

export default Map;
