import React from "react";
import Drawer from "@material-ui/core/Drawer";
import DateRange from "../DateRange/DateRange";
import CloseFilterButton from "../Dashboard/CloseFilterButton";
import LocationFilters from "./LocationFilters";
import FilterButton from "../Dashboard/FilterButton";
import ResetFilters from "./ResetFilters";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  drawerContentContainer: {
    padding: "20px 25px",
  },
  drawerPaper: {
    backgroundColor: "#FAFAFA",
    width: "90%",
  },
});

const FiltersDrawer = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => setIsOpen(!isOpen);
  const classes = useStyles();
  return (
    <>
      {!isOpen && <FilterButton onClick={toggleDrawer} />}
      <Drawer
        anchor="left"
        open={isOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerContentContainer}>
          <CloseFilterButton onClick={toggleDrawer} />
          <LocationFilters />
          <DateRange />
          <ResetFilters isMobile={true} />
        </div>
      </Drawer>
    </>
  );
};

export default FiltersDrawer;
