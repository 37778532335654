import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import { useCallback, useEffect, useMemo } from "react";
import { totalCatchPerMonthSlice } from "../../redux/chartSlice";
import { useDispatch, useSelector } from "react-redux";
import DateFnsAdapter from "@date-io/date-fns";
import YearsLegend from "./YearsLegend";
import {
  selectSelectedYear,
  setSelectedYear,
} from "../../redux/chartsSettingsSlice";

const dateFns = new DateFnsAdapter();

const TotalCatchPerMonth = () => {
  const data = useSelector(totalCatchPerMonthSlice.selectAll);
  const dispatch = useDispatch();
  const selectedYear = useSelector(selectSelectedYear);
  const onYearSelect = useCallback((year) => dispatch(setSelectedYear(year)), [
    dispatch,
  ]);

  const allData = useMemo(
    () =>
      data.map(({ month, sum_weight_mt }) => ({
        x: new Date(month),
        y: sum_weight_mt,
      })),
    [data]
  );
  //Extract years
  const years = useMemo(
    () =>
      allData
        .reduce((years, { x }) => {
          const year = x.getFullYear();
          if (!years.includes(year)) {
            years.push(year);
          }
          return years;
        }, [])
        .reverse(),
    [allData]
  );

  //If selected year from state is not in range of years from current data
  useEffect(() => {
    const firstYear = years[0];
    if (!years.find((year) => selectedYear === year) && firstYear) {
      onYearSelect(firstYear);
    }
  }, [onYearSelect, selectedYear, years]);

  //Filter data by selectedYear
  const selectedYearData = useMemo(
    () => allData.filter(({ x }) => x.getFullYear() === selectedYear),
    [selectedYear, allData]
  );

  const xAxisTickValues = useMemo(() => selectedYearData.map(({ x }) => x), [
    selectedYearData,
  ]);

  return (
    <VictoryChart
      theme={VictoryTheme.material}
      animate={{ duration: 500 }}
      width={600}
      height={300}
      domainPadding={{ x: [30, 30], y: [30, 30] }}
      padding={{ top: 40, bottom: 35, left: 50, right: 10 }}
      scale={{ x: "time" }}
      containerComponent={
        <VictoryVoronoiContainer
          voronoiPadding={10}
          voronoiDimension={"x"}
          labels={({ datum: { y, x } }) =>
            `${dateFns.format(x, "MMM yyyy")}\nMetric tons: ${y.toFixed(2)}`
          }
          labelComponent={
            <VictoryTooltip
              constrainToVisibleArea
              pointerWidth={15}
              pointerLength={20}
              labelComponent={<VictoryLabel lineHeight={1.5} />}
              style={{
                fontSize: 17,
                fill: "#5CB9EA",
              }}
              flyoutStyle={{
                fill: "white",
                stroke: "#5CB9EA",
                strokeWidth: 0.5,
              }}
              flyoutPadding={15}
              cornerRadius={2}
            />
          }
          style={{ touchAction: "auto" }}
        />
      }
    >
      <VictoryAxis
        dependentAxis
        style={{
          axis: { stroke: "#575B66", strokeWidth: 0.1 },
          axisLabel: { padding: 35 },
          tickLabels: { fontSize: 16, fill: "#5cb9ea" },
          grid: { stroke: "#575B66", strokeWidth: 0.1 },
          ticks: { stroke: "#5cb9ea", size: 5 },
        }}
      />

      <VictoryAxis
        tickFormat={(x) => dateFns.format(x, "MMM")}
        tickValues={xAxisTickValues}
        style={{
          axis: { stroke: "#575B66", strokeWidth: 0.1 },
          axisLabel: { fontSize: 20, padding: 30 },
          // ticks: { stroke: "#575B66", size: 5 },
          tickLabels: { fontSize: 16, padding: 5 },
        }}
      />
      <VictoryBar
        barWidth={30}
        style={{
          data: { fill: "#5CB9EA" },
        }}
        cornerRadius={5}
        data={selectedYearData}
      />
      <YearsLegend
        years={years}
        selectedYear={selectedYear}
        onYearSelect={onYearSelect}
      />
    </VictoryChart>
  );
};

export default TotalCatchPerMonth;
