import React from "react";
import { Marker, Popup } from "react-leaflet";
import Leaflet from "leaflet";
import "./MapMarker.css";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { formatNumber } from "../../utils/numberHumanizer";

const tooltipItems = [
  { label: "Community", key: "community_name" },
  { label: "Population", key: "population" },
  { label: "Estimated fishers", key: "est_fishers", format: formatNumber },
  { label: "Estimated buyers", key: "est_buyers", format: formatNumber },
  {
    label: "Total catch weight (metric tons)",
    key: "total_catch_weight",
    format: formatNumber,
  },
  {
    label: "Total catch value (USD)",
    key: "total_catch_value",
    format: formatNumber,
  },
];
const ToolTipLabel = withStyles((theme) => ({
  root: {
    fontSize: 11,
    color: "#FFFFFF",
  },
  subtitle2: {
    color: "#99F2E8",
    fontWeight: "normal",
  },
}))(Typography);

const MapMarker = ({ data }) => {
  return (
    <Marker
      icon={Leaflet.divIcon({
        // html: "<div></div>",
        iconSize: [12, 12],
        className: "MarkerIcon",
      })}
      position={[data.community_lat, data.community_lon]}
    >
      <Popup minWidth={270} className={"ToolTip"}>
        <Grid container>
          {tooltipItems.map(({ label, key, format }) => (
            <React.Fragment key={key}>
              <Grid item={true} xs={8}>
                <ToolTipLabel variant={"caption"}>{label}:</ToolTipLabel>
              </Grid>
              <Grid item={true} xs={4}>
                <ToolTipLabel variant={"subtitle2"}>
                  {!!format ? format(data[key]) : data[key]}
                </ToolTipLabel>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Popup>
    </Marker>
  );
};
export default MapMarker;
