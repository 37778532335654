import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const createChartSlice = (sliceName, fetchAll, fetchParamsSelector) => {
  const fetchData = createAsyncThunk(
    `chart/${sliceName}/fetchAll`,
    async (arg, { getState }) => {
      const params = fetchParamsSelector && fetchParamsSelector(getState());
      console.log(`chart/${sliceName}/fetchAll`, params);
      const { data } = await fetchAll(params);
      return data;
    }
  );

  const initialState = {
    items: [],
    loading: false,
    loaded: false,
  };

  const slice = createSlice({
    name: sliceName,
    initialState,
    extraReducers: (builder) => {
      builder
        .addCase(fetchData.pending, (state, action) => {
          state.loading = true;
          state.loaded = false;
        })
        .addCase(fetchData.fulfilled, (state, action) => {
          state.items = action.payload;
          state.loading = false;
          state.loaded = true;
        })
        .addCase(fetchData.rejected, (state, action) => {
          state.loading = false;
          state.loaded = true;
        });
    },
  });

  const reducer = slice.reducer;

  //selectors
  const selectSelf = (state) => state[sliceName];
  const selectAll = createSelector(selectSelf, ({ items }) => items);
  const selectIsLoading = createSelector(selectSelf, ({ loading }) => loading);
  const selectIsLoaded = createSelector(selectSelf, ({ loaded }) => loaded);

  return {
    reducer,
    //Actions
    fetchData,
    //Selectors
    selectIsLoading,
    selectIsLoaded,
    selectAll,
  };
};
export default createChartSlice;
