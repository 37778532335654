import {
    VictoryAxis,
    VictoryChart,
    VictoryGroup,
    VictoryLabel,
    VictoryLegend,
    VictoryLine,
    VictoryScatter,
    VictoryTheme,
    VictoryTooltip,
    VictoryVoronoiContainer,
  } from "victory";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import DateFnsAdapter from "@date-io/date-fns";
import YearsLegend from "./YearsLegend";
import {
selectSelectedYear,
setSelectedYear,
} from "../../redux/chartsSettingsSlice";

const GenericLineChart = ({axisLabels, leftSide, rightSide, data}) => {
    const dateFns = new DateFnsAdapter();
    const axisOrientation = ["left", "right"];
    const axisOffsetX = [50, 50];
    const colors = ["#5cb9ea", "#f47762"];
    
    const dispatch = useDispatch();
    const selectedYear = useSelector(selectSelectedYear);
    const onYearSelect = useCallback((year) => dispatch(setSelectedYear(year)), [
    dispatch,
    ]);
    const allData = useMemo(
    () => [
        data.map(({ month, [leftSide]: y }) => ({
        x: new Date(month),
        y,
        })),
        data.map(({ month, [rightSide]: y }) => ({
        x: new Date(month),
        y,
        })),
    ],
    [data]
    );

    const years = useMemo(
    () =>
        data
        .reduce((years, { month }) => {
            const year = new Date(month).getFullYear();
            if (!years.includes(year)) {
            years.push(year);
            }
            return years;
        }, [])
        .reverse(),
    [data]
    );

    //If selected year from state is not in range of years from current data
    useEffect(() => {
    const firstYear = years[0];
    if (!years.find((year) => selectedYear === year) && firstYear) {
        onYearSelect(firstYear);
    }
    }, [onYearSelect, selectedYear, years]);

    //Filter data by selectedYear
    const selectedYearData = useMemo(
    () =>
        allData.map((dataset) =>
        dataset.filter(({ x }) => x.getFullYear() === selectedYear)
        ),
    [selectedYear, allData]
    );

    // find maxima for normalizing data
    const maxima = useMemo(
    () =>
        selectedYearData.map((dataset) => Math.max(...dataset.map((d) => d.y))),
    [selectedYearData]
    );

    // Re-scale ticks by multiplying by correct maxima
    const tickFormats = [
    (t) => Math.round(t * maxima[0]),
    (t) => (t * maxima[1]).toFixed(2),
    ];

    const labelLegendData = useMemo(
    () =>
        selectedYearData.map((d, i) => ({
        name: axisLabels[i],
        symbol: {
            fill: colors[i],
        },
        })),
    [selectedYearData]
    );

    return (
    <div>
        <VictoryChart
        theme={VictoryTheme.material}
        animate={{ duration: 500 }}
        width={600}
        height={300}
        domain={{ y: [0, 1] }}
        domainPadding={20}
        padding={{ top: 50, bottom: 60, left: 50, right: 50 }}
        // scale={{ x: "time" }}
        containerComponent={
            <VictoryVoronoiContainer
            voronoiPadding={10}
            voronoiDimension={"x"}
            voronoiBlacklist={["line_component"]}
            labels={({ datum }) => {
                const itemLabel = axisLabels[datum.childName.split("-")[1]];
                return `${itemLabel}: ${datum.y.toFixed(2)}`;
            }}
            labelComponent={
                <VictoryTooltip
                constrainToVisibleArea
                pointerWidth={15}
                pointerLength={20}
                labelComponent={<VictoryLabel lineHeight={1.5} />}
                centerOffset={{ y: -40 }}
                flyoutStyle={{
                    fill: "white",
                    stroke: "#5CB9EA",
                    strokeWidth: 0.5,
                }}
                flyoutPadding={15}
                cornerRadius={2}
                />
            }
            style={{ touchAction: "auto" }}
            />
        }
        >
        <VictoryAxis
            orientation="bottom"
            tickFormat={(x) => dateFns.format(x, "MMM")}
            tickValues={selectedYearData[0].map(({ x }) => x)}
            style={{
            axis: { stroke: "#575B66", strokeWidth: 0.1 },
            axisLabel: { fontSize: 20, padding: 30 },
            // grid: {},
            // ticks: { stroke: "#575B66", size: 5 },
            tickLabels: { fontSize: 16, padding: 5 },
            }}
        />
        {selectedYearData.map((d, i) => (
            <VictoryAxis
            key={`axis_${i}`}
            dependentAxis
            // label={axisLabels[i]}
            orientation={axisOrientation[i]}
            offsetX={axisOffsetX[i]}
            style={{
                axis: { stroke: colors[i], strokeWidth: 0.1 },
                axisLabel: { padding: 35 },
                grid: { stroke: colors[i], strokeWidth: 0.1 },
                ticks: { stroke: colors[i], size: 5, padding: -5 },
                tickLabels: { fontSize: 16, fill: colors[i] },
            }}
            tickValues={[0.25, 0.5, 0.75, 1]}
            tickFormat={tickFormats[i]}
            />
        ))}

        {selectedYearData.map((d, i) => (
            <VictoryGroup
            key={`group_${i}`}
            data={d}
            // normalize data
            y={(datum) => datum.y / maxima[i]}
            >
            <VictoryLine
                name={"line_component"}
                style={{ data: { stroke: colors[i] } }}
                interpolation="natural"
            />
            <VictoryScatter
                name={`data_scatter-${i}`}
                size={({ active }) => (active ? 5 : 4)}
                style={{
                data: {
                    fill: ({ active }) => (active ? colors[i] : "white"),
                    stroke: colors[i],
                    strokeWidth: 1,
                },
                labels: { fill: colors[i], fontSize: 17 },
                }}
            />
            </VictoryGroup>
        ))}

        <VictoryLegend
            x={75}
            y={275}
            titleOrientation="left"
            gutter={40}
            style={{
            data: {
                type: "square",
                size: 10,
            },
            labels: {
                fontSize: 16,
            },
            }}
            data={labelLegendData}
            orientation="horizontal"
        />

        <YearsLegend
            years={years}
            selectedYear={selectedYear}
            onYearSelect={onYearSelect}
        />
        </VictoryChart>
    </div>
    );
}
  export default GenericLineChart;
  