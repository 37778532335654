import MultiSelectDropdown from "./MultiSelectDropdown";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lguSlice, snuSlice, maSlice } from "../../redux/filterSlice";

const LocalGovernmentFilter = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(lguSlice.selectIsLoading);
  const allLGU = useSelector(lguSlice.selectAll);
  const allSNUEntities = useSelector(snuSlice.selectEntities);
  const selectedSNU = useSelector(snuSlice.selectSelectedIDs);
  // const groupBy = useMemo(() => (selectedSNU.length > 1 ? "snu_name" : null), [
  //   selectedSNU,
  // ]);
  const options = useMemo(
    () =>
      allLGU
        .filter(
          ({ snu_id }) => !selectedSNU.length || selectedSNU.includes(snu_id)
        )
        .map((item) => ({
          snu_name: allSNUEntities[item.snu_id]?.snu_name,
          ...item,
        })),
    [allLGU, allSNUEntities, selectedSNU]
  );
  const selectedValues = useSelector(lguSlice.selectSelected);

  const onItemSelectionChange = (items, isSelected) => {
    dispatch(lguSlice.setSelected({ items, isSelected }));
    dispatch(
      maSlice.setSelectedByParent({
        key: "lgu_id",
        parents: items,
        isSelected: isSelected,
      })
    );
  };

  return (
    <MultiSelectDropdown
      id={"localGovernment"}
      label={"Local Government"}
      options={options}
      selectedValues={selectedValues}
      getOptionSelected={(option, value) => option.lgu_id === value.lgu_id}
      // groupBy={groupBy}
      onItemSelectionChange={onItemSelectionChange}
      displayValue={"lgu_name"}
      loading={isLoading}
      closeOnSelect={false}
    />
  );
};
export default LocalGovernmentFilter;
