import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const DatePickerStyled = withStyles((theme) => ({
  root: {
    margin: 0,
    width: "100%",
  },
}))(DatePicker);

const useStyles = makeStyles(() => ({
  input: {
    textAlign: "center",
    padding: 10,
  },
  "@media (min-width: 667px)": {
    input: {
      padding: "4px",
    },
  },
  notchedOutline: {
    borderWidth: 2,
    borderColor: "#CACEDB",
  },
}));

const DatesPicker = ({ dateValue, onChange, minDate, maxDate }) => {
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePickerStyled
        disableToolbar={false} //toolbar provides quick way to change the year
        autoOk={true}
        variant="inline"
        format="MM/dd/yyy"
        margin="normal"
        value={dateValue}
        minDate={minDate}
        maxDate={maxDate}
        inputVariant="outlined"
        onChange={onChange}
        InputProps={{
          classes: classes,
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatesPicker;
