import React, { useEffect } from "react";
import { useMap } from "react-leaflet";
import MapMarker from "./MapMarker";
import { useSelector } from "react-redux";
import { selectAll } from "../../redux/communitiesSlice";

const MapMarkers = () => {
  const map = useMap();
  const communities = useSelector(selectAll);
  //Fit map
  useEffect(() => {
    communities.length &&
      map.fitBounds(
        communities.map(({ community_lat, community_lon }) => [
          community_lat,
          community_lon,
        ]),
        {
          paddingTopLeft: [0, 15],
          paddingBottomRight: [0, 15],
        }
      );
  }, [map, communities]);

  return (
    <>
      {communities.map((community, index) => (
        <MapMarker key={index} data={community} />
      ))}
    </>
  );
};
export default MapMarkers;
