import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { formatNumber } from "../../utils/numberHumanizer";
import { withStyles } from "@material-ui/core/styles";

const CardItem = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(82, 132, 206, 0.5)",
    backdropFilter: "blur(15px)",
    color: "#ffffff",
    textAlign: "center",
    padding: "0px 5px 0px 5px",
    minHeight: "38px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "@media (min-width: 667px)": {
    root: {
      minHeight: "45px",
    },
  },
}))(Paper);

const CardLabel = withStyles((theme) => ({
  root: {
    fontSize: "0.6rem",
    lineHeight: 1.6,
  },
  "@media (min-width: 667px)": {
    root: {
      fontSize: "0.75rem",
      lineHeight: 2,
    },
  },
}))(Typography);

const CardValue = withStyles((theme) => ({
  root: {
    fontSize: "1.1rem",
    lineHeight: 1.4,
  },
  "@media (min-width: 667px)": {
    root: {
      lineHeight: 1.66,
    },
  },
}))(Typography);

const InfoCard = ({ value, label }) => (
  <CardItem elevation={0}>
    <CardValue variant={"caption"}>{formatNumber(value)}</CardValue>
    <CardLabel variant={"subtitle1"}>{label}</CardLabel>
  </CardItem>
);

export default InfoCard;
