import { configureStore } from "@reduxjs/toolkit";
import {
  countryReducer,
  localGovernmentReducer,
  managedAccessAreasReducer,
  subnationalUnitsReducer,
} from "./filterSlice";
import communitiesReducer from "./communitiesSlice";
import totalsReducer from "./totalsSlice";
import dateRangeReducer from "./dateRangeFilterSlice";
import {
  catchCompositionTop10Reducer,
  totalCatchPerMonthReducer,
  avgCatchValueCPUEReducer,
  medianLengthReducer
} from "./chartSlice";
import catchCompositionTop10Settings from "./catchCompositionTop10SettingsSlice";
import chartsSettingsReducer from "./chartsSettingsSlice";

export default configureStore({
  reducer: {
    countries: countryReducer,
    subnational_units: subnationalUnitsReducer,
    local_government_units: localGovernmentReducer,
    managed_access_areas: managedAccessAreasReducer,
    dateRange: dateRangeReducer,
    communities: communitiesReducer,
    totals: totalsReducer,
    catchCompositionTop10: catchCompositionTop10Reducer,
    catchCompositionTop10Settings: catchCompositionTop10Settings,
    totalCatchPerMonth: totalCatchPerMonthReducer,
    avgCatchValueCPUE: avgCatchValueCPUEReducer,
    chartsSettings: chartsSettingsReducer,
    medianLength: medianLengthReducer
  },
});
