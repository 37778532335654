import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  label: {
    color:"#5492DA",
    lineHeight: 2
  },
  "@media (min-width: 667px)": {
    label: {
      color:"#FFFFFF"
    },
  },
});

const filter = createFilterOptions();
const MultiSelectDropdown = ({ label, onItemSelectionChange, ...rest }) => {
  const classes = useStyles();
  const isAllSelected = rest.selectedValues.length === rest.options.length;
  const handleChange = (
    event,
    selectedOptions,
    reason,
    { option: changeOption } = {}
  ) => {
    // console.log("selectedOptions", selectedOptions.length, reason);
    if (reason === "select-option" || reason === "remove-option") {
      if (selectedOptions.find((option) => option.value === "select-all")) {
        onItemSelectionChange(
          rest.options,
          rest.selectedValues.length !== rest.options.length
        );
      } else {
        onItemSelectionChange([changeOption], reason === "select-option");
      }
    } else if (reason === "clear") {
      onItemSelectionChange(rest.options, false);
    }
  };
  return (
    <div>
      <label className={classes.label}>{label}</label>
      <Autocomplete
        multiple
        size="small"
        disableCloseOnSelect={true}
        disableClearable={true} //clear button is too close to arrow icon and can be easily clicked by mistake
        options={rest.options}
        value={rest.selectedValues}
        onChange={handleChange}
        getOptionSelected={rest.getOptionSelected}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          return [
            { [rest.displayValue]: "Select all", value: "select-all" },
            ...filtered,
          ];
        }}
        {...(rest.groupBy
          ? {
              groupBy: (option) =>
                option.value === "select-all" ? null : option[rest.groupBy],
            }
          : {})}
        // groupBy={(option) =>
        //   rest.groupBy &&
        //   (option.value === "select-all" ? null : option[rest.groupBy])
        // }
        getOptionLabel={(option) => option[rest.displayValue] || ""}
        renderOption={(option, { selected }) => {
          // console.log(option, rest.selectedValues.length, rest.options.length);
          const isSelected =
            option.value === "select-all" ? isAllSelected : selected;
          return (
            <React.Fragment>
              <Checkbox
                color={"primary"}
                size="small"
                // icon={icon}
                // checkedIcon={checkedIcon}
                indeterminate={
                  option.value === "select-all" &&
                  rest.selectedValues.length > 0 &&
                  rest.selectedValues.length < rest.options.length
                }
                checked={isSelected}
              />
              {option[rest.displayValue]}
            </React.Fragment>
          );
        }}
        renderTags={(tagValue, getTagProps) => {
          return null;
          // return isAllSelected ? (
          //   <div key={"all"}>All</div>
          // ) : tagValue.length < 2 ? (
          //   tagValue.map(
          //     (option, index) => option && option[rest.displayValue]
          //     // <Chip label={option.title} {...getTagProps({ index })} />
          //   )
          // ) : (
          //   [<div key={"multi"}>Multiple</div>]
          // );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            // label=""
            variant="outlined"
            placeholder={
              isAllSelected || !rest.selectedValues.length
                ? "All"
                : rest.selectedValues.length === 1
                ? rest.selectedValues[0][rest.displayValue]
                : "Multiple"
            }
          />
        )}
      />
    </div>
  );
};

export default MultiSelectDropdown;
