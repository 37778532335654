import { createSelector, createSlice } from "@reduxjs/toolkit";
import DateFnsAdapter from "@date-io/date-fns";
import { resetFilters } from "./filterSlice";
const dateFns = new DateFnsAdapter();

const defaultMonthsToLookBack = 3;
const startDate = new Date();
const endDate = new Date();
startDate.setMonth(endDate.getMonth() - defaultMonthsToLookBack);
startDate.setHours(0, 0, 0, 0);
endDate.setHours(0, 0, 0, 0);

const initialState = {
  start: startDate.getTime(),
  end: endDate.getTime(),
};
const slice = createSlice({
  name: "dateRange",
  initialState,
  reducers: {
    setDateRange: (state, action) => {
      const { start, end } = action.payload;
      // console.log("dateRange", new Date(start), new Date(end));
      state.start = start;
      state.end = end;
    },
    setStartDate: (state, action) => {
      // console.log("dateRange: START", new Date(action.payload));
      state.start = action.payload;
    },
    setEndDate: (state, action) => {
      // console.log("dateRange: END", new Date(action.payload));
      state.end = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetFilters, (state, action) => {
      state.start = initialState.start;
      state.end = initialState.end;
    });
  },
});
//selectors
export const selectDateRange = (state) => state.dateRange;
export const selectDateRangeAsHumanReadable = createSelector(
  [selectDateRange],
  ({ start, end }) => ({ start: new Date(start), end: new Date(end) })
);
const asFilterValue = (date) => dateFns.format(date, "yyyy-MM-dd");
export const selectDateRangeAsFilter = createSelector(
  [selectDateRange],
  ({ start, end }) => ({ start: asFilterValue(start), end: asFilterValue(end) })
);

const { reducer, actions } = slice;
export const { setDateRange, setEndDate, setStartDate } = actions;
export default reducer;
