import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  year: new Date().getFullYear(),
};
const slice = createSlice({
  name: "chartsSettings",
  initialState,
  reducers: {
    setSelectedYear: (state, action) => {
      state.year = action.payload;
    },
  },
});

const { reducer, actions } = slice;

//Actions
export const { setSelectedYear } = actions;

//selectors
const selectSelf = (state) => state.chartsSettings;
export const selectSelectedYear = createSelector(
  selectSelf,
  ({ year }) => year
);
export default reducer;
