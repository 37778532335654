import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { resetFilters } from "./filterSlice";

const createFilterSlice = (sliceName, id_field, title_field, fetchAll) => {
  const fetchData = createAsyncThunk(
    `filter/${sliceName}/fetchAll`,
    async () => {
      console.log(`filter/${sliceName}/fetchAll`);
      const { data } = await fetchAll();
      return data;
    }
  );

  const filterAdapter = createEntityAdapter({
    selectId: (item) => item[id_field],
    sortComparer: (a, b) => a[title_field]?.localeCompare(b[title_field]),
  });

  const {
    selectById,
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
  } = filterAdapter.getSelectors((state) => state[sliceName]);

  const initialState = filterAdapter.getInitialState({
    loading: false,
    loaded: false,
    selectedIDs: [],
  });

  const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
      setSelected: (state, action) => {
        const { items, isSelected } = action.payload;
        const ids = items.map(({ [id_field]: id }) => id);
        if (isSelected) {
          state.selectedIDs.push(
            ...ids.filter((id) => !state.selectedIDs.includes(id))
          );
        } else {
          state.selectedIDs = state.selectedIDs.filter(
            (id) => !ids.includes(id)
          );
        }
      },
      setSelectedByParent: (state, action) => {
        const { key, parents, isSelected } = action.payload;
        console.log(key, parents.length, isSelected);

        const parentIds = parents.map(({ [key]: id }) => id);
        const idsByParent = state.ids.filter((id) =>
          parentIds.includes(state.entities[id][key])
        );

        if (isSelected) {
          state.selectedIDs.push(
            ...idsByParent.filter((id) => !state.selectedIDs.includes(id))
          );
        } else {
          state.selectedIDs = state.selectedIDs.filter(
            (id) => !idsByParent.includes(id)
          );
        }
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchData.pending, (state, action) => {
          state.loading = true;
        })
        .addCase(fetchData.fulfilled, (state, action) => {
          const items = action.payload.filter(({ [id_field]: id }) => !!id);
          filterAdapter.setAll(state, items);
          // state.selectedIDs = items.map(({ [id_field]: id }) => id);
          state.loading = false;
          state.loaded = true;
        })
        .addCase(resetFilters, (state, action) => {
          state.selectedIDs = initialState.selectedIDs;
        });
    },
  });

  const reducer = slice.reducer;
  const { setSelected, setSelectedByParent } = slice.actions;

  //selectors
  const selectSelf = (state) => state[sliceName];
  const selectIsLoading = createSelector(selectSelf, ({ loading }) => loading);
  const selectIsLoaded = createSelector(selectSelf, ({ loaded }) => loaded);
  const selectSelectedIDs = createSelector(
    selectSelf,
    ({ selectedIDs }) => selectedIDs
  );
  const selectSelected = createSelector(
    [selectSelectedIDs, selectEntities],
    (selectedIDs, entities) => selectedIDs.map((id) => entities[id])
  );

  return {
    reducer,
    //Actions
    fetchData,
    setSelected,
    setSelectedByParent,
    //Selectors
    selectIsLoading,
    selectIsLoaded,
    selectById,
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
    selectSelected,
    selectSelectedIDs,
  };
};
export default createFilterSlice;
