import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  countrySlice,
  lguSlice,
  maSlice,
  snuSlice,
} from "../redux/filterSlice";

const useLoadInitData = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("Start App");
    //Load Filters
    dispatch(countrySlice.fetchData());
    dispatch(snuSlice.fetchData());
    dispatch(lguSlice.fetchData());
    dispatch(maSlice.fetchData());
  }, [dispatch]);
};

export default useLoadInitData;
