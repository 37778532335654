import React from "react";
import Drawer from "@material-ui/core/Drawer";
import CloseFilterButton from "../Dashboard/CloseFilterButton";
import FilterButton from "../Dashboard/FilterButton";
import LocationFilters from "./LocationFilters";
import ResetFilters from "./ResetFilters";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  boxContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    position: "absolute",
    top: "0",
    zIndex: 1002,
    width: "20%",
    padding: "10px 0 0 25px",
  },
  drawerContentContainer: {
    padding: "20px 25px",
    left: 0,
    backgroundColor: 'transparent',
    position: 'relative'
  },
  drawerPaper: {
    backgroundColor: 'transparent',
    width: "26%",
    position: "absolute",
    borderRight: "none",
  },
  "@media (min-width: 1171px)": {
      drawerPaper: {
          width: "20%",
      },
  },
});

const Filters = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleFilters = () => setIsOpen(!isOpen);
  const classes = useStyles();
  return (
    <>
      {!isOpen && <FilterButton onClick={toggleFilters} />}
      { 
      <div>
      <Drawer 
        open={isOpen}
        anchor="left"
        BackdropProps={{ invisible: true}}
        classes={{
          paper: classes.drawerPaper,
        }}
        variant="persistent"
      >
        <div className={classes.drawerContentContainer} id="drawer-container">
            <CloseFilterButton onClick={toggleFilters} />
            <LocationFilters />
            <ResetFilters />
        </div>
        </Drawer>
      </div>

      }
    </>
  )
};

export default Filters;
