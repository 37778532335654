import { createSelector } from "@reduxjs/toolkit";
import { selectMASelectedIDsAsFilter } from "./filterSlice";
import { selectDateRangeAsFilter } from "./dateRangeFilterSlice";

export const selectMAIDsAndDateRangeFilters = createSelector(
  [selectMASelectedIDsAsFilter, selectDateRangeAsFilter],
  (selectedMaIDs, dateRange) => ({
    ...selectedMaIDs,
    ...dateRange,
  })
);
