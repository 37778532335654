import { VictoryLabel, VictoryLegend } from "victory";
import { useCallback, useMemo } from "react";

const YearsLegend = ({ years, selectedYear, onYearSelect }) => {
  const dataYears = useMemo(() => years.map((year) => ({ name: `${year}` })), [
    years,
  ]);

  const getYearColor = useCallback(
    (yearString) =>
      yearString === selectedYear?.toString() ? "#6C9EE9" : "#575B66",
    [selectedYear]
  );

  return (
    <VictoryLegend
      standalone={false}
      data={dataYears}
      title="Year: "
      titleOrientation="left"
      orientation="horizontal"
      gutter={20}
      symbolSpacer={8}
      titleComponent={<VictoryLabel dy={-7} />}
      y={0}
      style={{
        title: {
          fontSize: 17,
        },
        data: {
          size: 4,
          cursor: "pointer",
          stroke: ({ datum }) => getYearColor(datum.name),
          strokeWidth: 1,
          fill: ({ datum }) =>
            datum.name === selectedYear?.toString()
              ? getYearColor(datum.name)
              : "transparent",
        },
        labels: {
          cursor: "pointer",
          fontSize: 17,
          fill: ({ datum }) => getYearColor(datum.name),
        },
      }}
      events={[
        {
          eventHandlers: {
            onClick: (e, { datum }, index) => {
              index !== "all" && onYearSelect(years[index]);
            },
            onMouseOver: (e, props) => {
              return [
                {
                  target: "data",
                  mutation: (props) => ({
                    style: { ...props.style, fill: "#5CB9EA" },
                  }),
                },
                {
                  target: "labels",
                  mutation: (props) => ({
                    style: { ...props.style, fill: "#5CB9EA" },
                  }),
                },
              ];
            },
            onMouseOut: () => [
              {
                target: "data",
                mutation: (props) => null,
              },
              {
                target: "labels",
                mutation: (props) => null,
              },
            ],
          },
        },
      ]}
    />
  );
};
export default YearsLegend;
