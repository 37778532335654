import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { exportComponentAsPNG } from 'react-component-export-image';

const styles = {
  downloadButton: {
    color: '#5275BA',
    cursor: 'pointer',
  },
}

const DownloadChartButton = ({ chartRef, fileName }) => {
  const exportPNG = () => exportComponentAsPNG(chartRef, {fileName})
  return (
    <GetAppIcon onClick={exportPNG} style={styles.downloadButton} />
  )
}

export default DownloadChartButton;