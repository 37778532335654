import { avgCatchValueCPUESlice } from "../../redux/chartSlice";
import GenericLineChart from './GenericLineChart';
import { useSelector } from 'react-redux';

const AvgCatchValue = () => {
    const data = useSelector(avgCatchValueCPUESlice.selectAll);
    return <GenericLineChart 
                axisLabels={["Avg Catch Value (USD)", "Avg CPUE/Trip (Metric Tons)"]} 
                leftSide={"avg_catch_value_usd"} 
                rightSide={"avg_cpue_mt_trip"} 
                data={data}
            />
}

export default AvgCatchValue;