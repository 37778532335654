import React from "react";
import { CSVLink } from "react-csv";
import { selectMAIDsAndDateRangeFilters } from "../../redux/selectors";
import { useSelector } from "react-redux";
import { getExportData } from "../../services/DataWorldAPI";
import { useRef, useEffect, useState, useCallback } from "react";
import getDateStr from "../../utils/getDateStr";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";

const headers = [
  { label: "id", key: "id" },
  { label: "date", key: "date" },
  { label: "country", key: "country" },
  { label: "snu_name", key: "snu_name" },
  { label: "lgu_name", key: "lgu_name" },
  { label: "community_name", key: "community_name" },
  { label: "ma_name", key: "ma_name" },
  { label: "population", key: "population" },
  { label: "community_lat", key: "community_lat" },
  { label: "community_lon", key: "community_lon" },
  { label: "weight_mt", key: "weight_mt" },
  { label: "count", key: "count" },
  { label: "total_price_usd", key: "total_price_usd" },
  { label: "family_scientific", key: "family_scientific" },
  { label: "family_local", key: "family_local" },
  { label: "species_scientific", key: "species_scientific" },
  { label: "species_local", key: "species_local" },
  { label: "a", key: "a" },
  { label: "b", key: "b" },
  { label: "lmax", key: "lmax" },
];

const Spinner = withStyles({
  root: {
    color: "#99F2E8",
    marginLeft: 8,
  },
})(CircularProgress);

const DownloadCSV = () => {
  const [csvData, setCsvData] = useState(false);
  const [fetchingCsvData, setFetchingCsvData] = useState(false);
  const csvInstance = useRef();
  const filters = useSelector(selectMAIDsAndDateRangeFilters);

  useEffect(() => {
    if (csvInstance?.current?.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData(false);
      });
    }
  }, [csvData]);

  const fetchCsvData = useCallback(
    async (e) => {
      e.preventDefault();
      setFetchingCsvData(true);
      try {
        const { data } = await getExportData(filters);
        setCsvData(data);
      } finally {
        setFetchingCsvData(false);
      }
    },
    [filters]
  );

  return (
    <div>
      <a
        className={
          fetchingCsvData
            ? "disable-download-button download-csv-link"
            : "download-csv-link"
        }
        href="#"
        onClick={fetchCsvData}
      >
        Download Data
      </a>
      {fetchingCsvData && <Spinner size={15} variant={"indeterminate"} />}
      {csvData && (
        <CSVLink
          data={csvData}
          filename={`fisherDashboard-${getDateStr()}.csv`}
          headers={headers}
          ref={csvInstance}
        />
      )}
    </div>
  );
};

export default DownloadCSV;
