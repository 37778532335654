import { createSelector, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { maSlice, resetFilters } from "./filterSlice";
import { setDateRange, setEndDate, setStartDate } from "./dateRangeFilterSlice";

export const speciesRanges = { all: 0, focal: 1 };
export const speciesGroupings = {
  species: "species",
  family: "family",
};
export const speciesNamings = {
  local: "local",
  scientific: "scientific",
};
const defaultFishBaseIDs = [];
const slice = createSlice({
  name: "catchCompositionTop10Settings",
  initialState: {
    speciesRange: speciesRanges.all, // ?focal_sp - AS INTEGER = 0   -- 0 = All Species, 1 = Focal Species
    speciesGrouping: speciesGroupings.species, // ?group_by - 'species' or  'family'
    speciesNames: speciesNamings.scientific, //  'scientific' or  'local',
    fishBaseIDs: defaultFishBaseIDs,
    handleFishBaseIDsChange: 0,
  },
  reducers: {
    toggleSpeciesRange: (state) => {
      state.speciesRange =
        state.speciesRange === speciesRanges.all
          ? speciesRanges.focal
          : speciesRanges.all;
      if (state.fishBaseIDs.length > 0) {
        state.handleFishBaseIDsChange++; //state.fishBaseIDs.length > 0;
      }
      state.fishBaseIDs = defaultFishBaseIDs; //Reset fishBaseIDs selection
    },
    toggleSpeciesGrouping: (state) => {
      state.speciesGrouping =
        state.speciesGrouping === speciesGroupings.species
          ? speciesGroupings.family
          : speciesGroupings.species;
      if (state.fishBaseIDs.length > 0) {
        state.handleFishBaseIDsChange++; //state.fishBaseIDs.length > 0;
      }
      state.fishBaseIDs = defaultFishBaseIDs; //Reset fishBaseIDs selection
    },
    toggleSpeciesNames: (state) => {
      state.speciesNames =
        state.speciesNames === speciesNamings.local
          ? speciesNamings.scientific
          : speciesNamings.local;
    },
    addFishBaseIDs: (state, action) => {
      state.fishBaseIDs.push(
        ...action.payload.filter((id) => !state.fishBaseIDs.includes(id))
      );
      state.handleFishBaseIDsChange++;
    },
    removeFishBaseIDs: (state, action) => {
      state.fishBaseIDs = state.fishBaseIDs.filter(
        (id) => !action.payload.includes(id)
      );
      state.handleFishBaseIDsChange++;
    },
    removeAllFishBaseIDs: (state) => {
      state.fishBaseIDs = defaultFishBaseIDs;
      state.handleFishBaseIDsChange++;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        resetFilters,
        maSlice.setSelected,
        maSlice.setSelectedByParent,
        setDateRange,
        setStartDate,
        setEndDate
      ),
      (state) => {
        state.fishBaseIDs = defaultFishBaseIDs;
      }
    );
  },
});

//selectors
const selectSelf = (state) => state.catchCompositionTop10Settings;
export const selectSpeciesRange = createSelector(
  [selectSelf],
  ({ speciesRange }) => speciesRange
);
export const selectSpeciesGrouping = createSelector(
  [selectSelf],
  ({ speciesGrouping }) => speciesGrouping
);
export const selectSpeciesNames = createSelector(
  [selectSelf],
  ({ speciesNames }) => speciesNames
);
export const selectFishBaseIDs = createSelector(
  [selectSelf],
  ({ fishBaseIDs }) => fishBaseIDs
);
export const selectSelectedFishBaseIDsAsFilter = createSelector(
  [selectFishBaseIDs],
  (fishBaseIDs) => ({ fishbase_ids: fishBaseIDs.join(",") })
);
export const selectHandleFishBaseIDsChange = createSelector(
  [selectSelf],
  ({ handleFishBaseIDsChange }) => handleFishBaseIDsChange
);
const { reducer, actions } = slice;
export const {
  toggleSpeciesGrouping,
  toggleSpeciesNames,
  toggleSpeciesRange,
  addFishBaseIDs,
  removeFishBaseIDs,
  removeAllFishBaseIDs,
} = actions;
export default reducer;
