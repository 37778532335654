import { Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import React, { useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import DownloadChartButton from "./DownloadChartButton";

const ChartCardContent = withStyles((theme) => ({
  root: {
    padding: 10,
    boxShadow: "0px 3px 5px rgba(0,0,0,0.1)",
    marginBottom: "20px",
  },
}))(Paper);

const useStyles = makeStyles({
  title: {
    margin: "0px 0 10px 0",
  },
  filters: {
    marginBottom: 10,
  },
});

const Chart = ({ title, filters, children }) => {
  const classes = useStyles();
  const contentRef = useRef();

  return (
    <ChartCardContent>
      {filters && <div className={classes.title}>{filters}</div>}
      <div ref={contentRef}>
        <Typography
          variant="caption"
          component={"h2"}
          className={classes.title}
        >
          {title}
        </Typography>
        {children}
      </div>
      <DownloadChartButton chartRef={contentRef} fileName={title} />
    </ChartCardContent>
  );
};

export default Chart;
