import createFilterSlice from "./genericFilterSlice";
import {
  getCountries,
  getlocalGovernment,
  getManagedAccessAreas,
  getSubnationalUnits,
} from "../services/DataWorldAPI";
import { createAction, createSelector } from "@reduxjs/toolkit";

//Actions
export const resetFilters = createAction("filter/reset");

//Slices
//Countries
export const { reducer: countryReducer, ...countrySlice } = createFilterSlice(
  "countries",
  "country_id",
  "country_name",
  getCountries
);

//Subnational Unit
export const {
  reducer: subnationalUnitsReducer,
  ...snuSlice
} = createFilterSlice(
  "subnational_units",
  "snu_id",
  "snu_name",
  getSubnationalUnits
);

//Local Government
export const {
  reducer: localGovernmentReducer,
  ...lguSlice
} = createFilterSlice(
  "local_government_units",
  "lgu_id",
  "lgu_name",
  getlocalGovernment
);

//Managed Access Area
export const {
  reducer: managedAccessAreasReducer,
  ...maSlice
} = createFilterSlice(
  "managed_access_areas",
  "ma_id",
  "ma_name",
  getManagedAccessAreas
);

//Selectors
export const selectMASelectedIDsAsFilter = createSelector(
  [maSlice.selectSelectedIDs],
  (selectedMaIDs) => ({ ma_ids: selectedMaIDs.join(",") })
);
export const selectIsFiltersLoading = createSelector(
  [
    countrySlice.selectIsLoading,
    snuSlice.selectIsLoading,
    lguSlice.selectIsLoading,
    maSlice.selectIsLoading,
  ],
  (...args) => args.some((arg) => !!arg)
);
export const selectIsFiltersLoaded = createSelector(
  [
    countrySlice.selectIsLoaded,
    snuSlice.selectIsLoaded,
    lguSlice.selectIsLoaded,
    maSlice.selectIsLoaded,
  ],
  (...args) => args.every((arg) => !!arg)
);
