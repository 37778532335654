import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";
import { selectIsDataLoading } from "../../redux/component.selectors";
import { withStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import React from "react";

const Spinner = withStyles({
  root: {
    color: "#99F2E8",
  },
})(CircularProgress);
const ProgressBackdrop = withStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1000,
    color: "#fff",
    background: "rgba(0, 0, 0, 0.3)",
  },
}))(Backdrop);
const DataLoadingProgress = () => {
  const isDataLoading = useSelector(selectIsDataLoading);

  return (
    <ProgressBackdrop open={isDataLoading}>
      <Spinner size={100} variant={"indeterminate"} />
    </ProgressBackdrop>
  );
};
export default DataLoadingProgress;
