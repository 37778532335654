import React from "react";
import Map from "../Map/Map";
import Filters from "../Filters/Filters";
import Info from "../Info/Info";
import "./Dashboard.css";
import Charts from "../Charts/Charts";
import DateRange from "../DateRange/DateRange";
import DataLoadingProgress from "../Progress/DataLoadingProgress";

const DesktopDashboard = () => (
  <div className={"DashboardContainer"}>
    <Map />
    <Filters />
    <Charts />
    <Info />
    <DateRange />
    <DataLoadingProgress />
  </div>
);

export default DesktopDashboard;
