import Dashboard from "./components/Dashboard/Dashboard";
import useLoadInitData from "./hooks/useLoadInitData";
import DataLoader from "./components/DataLoader/DataLoader";

function App() {
  useLoadInitData();

  return (
    <>
      <DataLoader />
      <Dashboard />
    </>
  );
}

export default App;
