import React from "react";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter.svg";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const IconButton = withStyles(() => ({
  root: {
    zIndex: 2000,
    position: "absolute",
    top: "50px",
    left: "25px",
    fontSize: "1rem",
    color: "white",
    textTransform: "none",
  },
}))(Button);

const FilterButton = ({ onClick }) => {
  return (
    <IconButton startIcon={<FilterIcon />} onClick={onClick}>
      Filters
    </IconButton>
  );
};

export default FilterButton;
