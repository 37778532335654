import React from "react";
import { useMediaQuery } from "@material-ui/core";
import DesktopDashboard from "./DesktopDashboard";
import MobileDashboard from "./MobileDashboard";

const Dashboard = () => {
  const isMobile = useMediaQuery("(max-width: 667px)");
  return isMobile ? <MobileDashboard /> : <DesktopDashboard />;
};

export default Dashboard;
