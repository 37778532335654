import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import { resetFilters } from "../../redux/filterSlice";

const ResetButton = withStyles((theme, isMobile) => ({
  root: {
    textTransform: "none",
    marginTop: "50px",
    ...(!isMobile && {marginTop: "30px"})
  },
  contained: {
    color: "#FFFFFF",
    backgroundColor: "#4D78CC",
    "&:hover": { backgroundColor: "#436FC5" },
  },
  outlined: {
    color: "#5492DA",
    borderColor: "#5492DA",
  },
}))(Button);
const ResetFilters = ({ isMobile }) => {
  const dispatch = useDispatch();
  const onReset = () => dispatch(resetFilters());
  return (
    <ResetButton
      onClick={onReset}
      fullWidth={true}
      variant={isMobile ? "outlined" : "contained"}
    >
      Clear filters
    </ResetButton>
  );
};
export default ResetFilters;
