import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { getCommunities } from "../services/DataWorldAPI";
import { selectMAIDsAndDateRangeFilters } from "./selectors";

// Communities
export const fetchCommunities = createAsyncThunk(
  `communities/fetchCommunities`,
  async (arg, { getState }) => {
    const filters = selectMAIDsAndDateRangeFilters(getState());

    console.log("communities/fetchCommunities", filters);
    const { data } = await getCommunities(filters);
    return data;
  }
);

const initialState = {
  items: [],
  loading: false,
};
const slice = createSlice({
  name: "communities",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommunities.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchCommunities.fulfilled, (state, action) => {
        state.items = action.payload.filter(
          ({ community_lat, community_lon }) =>
            !!community_lat && !!community_lon
        );
        state.loading = false;
      });
  },
});

const { reducer } = slice;
//selectors

const selectSelf = (state) => state.communities;
export const selectAll = createSelector(selectSelf, ({ items }) => items);
export const selectIsLoading = createSelector(
  selectSelf,
  ({ loading }) => loading
);
export default reducer;
