import { medianLengthSlice } from "../../redux/chartSlice";
import GenericLineChart from './GenericLineChart';
import { useSelector } from 'react-redux';

const MedianLength = () => {
     const data = useSelector(medianLengthSlice.selectAll);
    return <GenericLineChart 
                axisLabels={["Median Length (cm)", "% Mature"]}
                leftSide={"median_length_cm"}
                rightSide={"prop_mature"}
                data={data}
            />
}

export default MedianLength;