import React from "react";
import "./Charts.css";
import CatchComposition from "./CatchComposition";
import TotalCatchPerMonth from "./TotalCatchPerMonth";
import Chart from "./Chart";
import CatchCompositionFilters from "./CatchCompositionFilters";
import DownloadCSV from "../DownloadCSV/DownloadCSV";
import SocioEcoData from "./SocioEcoData";
import AvgCatchValue from "./AvgCatchValue";
import MedianLength from "./MedianLength";
import Grid from "@material-ui/core/Grid";
const Charts = () => (
  <div className={"ChartsContainer"}>
    <div className={"ChartsContainerInner"}>
      <div className={"ChartsArea"}>
        <Chart
          filters={<CatchCompositionFilters />}
          title={"Catch composition (Top 10, Metric Tons)"}
        >
          <CatchComposition />
        </Chart>
        <Chart title={"Total catch per month (Metric tons)"}>
          <TotalCatchPerMonth />
        </Chart>
        <Chart title={"Average catch value & Average CPUE/Trip"}>
          <AvgCatchValue />
        </Chart>
        <Chart title={"Median Length (cm)/% Mature"}>
          <MedianLength />
        </Chart>
      </div>
      <div style={{ paddingTop: 10, paddingRight: 10 }}>
        <Grid container>
          <Grid item xs={12}>
            <DownloadCSV />
          </Grid>
          <Grid item xs={12}>
            <SocioEcoData />
          </Grid>
        </Grid>
      </div>
    </div>
  </div>
);
export default Charts;
