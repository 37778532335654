import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  getCatchTotals,
  getFishersTotalsBySex,
} from "../services/DataWorldAPI";
import { selectMAIDsAndDateRangeFilters } from "./selectors";

// Totals
export const fetchTotals = createAsyncThunk(
  `totals/fetchTotals`,
  async (arg, { getState }) => {
    const filters = selectMAIDsAndDateRangeFilters(getState());
    console.log("totals/fetchTotals", filters);
    const {
      data: [totalsData],
    } = await getCatchTotals(filters);
    const {
      data: [fishersData],
    } = await getFishersTotalsBySex(filters);
    return {
      ...totalsData,
      ...fishersData,
      total_female_fishers: fishersData.female,
    };
  }
);

const initialState = {
  loading: false,
  data: {
    total_trips: 0,
    total_buyers: 0,
    total_catch_weight: 0,
    total_catch_value: 0,
    total_fishers: 0,
    male: 0,
    female: 0,
    total_female_fishers: 0, //Alias of female
    unknown: 0,
  },
};
const slice = createSlice({
  name: "totals",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchTotals.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchTotals.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      });
  },
});

const { reducer } = slice;
//selectors

const selectSelf = (state) => state.totals;

export const selectIsLoading = createSelector(
  selectSelf,
  ({ loading }) => loading
);
export const selectTotals = createSelector(selectSelf, ({ data }) => data);

export default reducer;
