import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import { Provider } from "react-redux";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      input: {
        padding: "none",
      },
    },
    MuiInputBase: {
      input: {
        padding: "4px",
        // border: '3px solid #EBEDED'
      },
    },
    MuiAutocomplete: {
      root: {},
      inputRoot: {
        '&[class*="MuiOutlinedInput-root"]': {
          backgroundColor: "#fff",
          border: "none",
        },
        '& [class*="MuiOutlinedInput-input"]::placeholder': {
          opacity: 1,
        },
        '& [class*="MuiOutlinedInput-notchedOutline"]': {
          borderWidth: 0,
          boxShadow: "0px 2px 2px rgba(0,0,0,0.15)",
        },
      },
      listbox: {
        fontSize: 13,
      },
      option: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        '&[aria-selected="true"]': {
          backgroundColor: "transparent",
        },
      },
      groupUl: {
        "& $option": {
          paddingLeft: 8,
        },
      },
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
