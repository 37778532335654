import axios from "axios";
import { AppConfig } from "../config";

const queryIDs = {
  countries: "8ba90f2c-9eac-40dd-947a-e9024109df02",
  subnational_units: "c94b2d19-e1e9-4c34-9a6b-315cf01d25af",
  local_government_units: "46dda9e4-a491-4b64-a21f-69aae131d134",
  managed_access_areas: "3c735ba2-21c0-4dfa-bb37-16eb412b2c4b",
  communities: "fa3c4d1b-036d-4232-93b2-ada2d07008b9",
  catch_totals_by_ma: "d860383a-70fd-4776-a4f5-8cfda377f565", //5_catch_totals_by_ma
  total_fishers_male_female: "696fa988-42e2-4021-a21e-b478a3b3c2e5", //6_total_fishers_male_female
  catch_composition_top10: "ceb6388b-fe2e-47d8-9a00-dbee4cece385",
  total_catch_per_month: "e0b091b3-8123-4b9a-b889-200e3f2e3812",
  avg_catch_value_cpue: "e563dd85-f8b7-497b-b661-5b9f07b51d3d",
  median_length_catch: "62602629-e3d8-4a3d-98ae-db341b1259ab",
  export_catch: "b64b8866-308a-417d-8b79-41f3568eec89",
};

const client = axios.create({
  baseURL: "https://api.data.world/v0",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${AppConfig.dataWorldApiToken}`,
  },
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!axios.isCancel(error)) {
      return Promise.reject(error);
    }
  }
);

//TODO: implement
const CancelToken = axios.CancelToken;
const requestWithCanceler = (config) => {
  const canceler = CancelToken.source();
  const request = () =>
    client.request({
      ...config,
      cancelToken: canceler.token,
    });
  return [request, canceler];
};

const getQueryResults = (queryId) => client.get(`/queries/${queryId}/results`);
const getParametrizedQueryResults = (queryId, parmeters) =>
  client.request({
    url: `/queries/${queryId}/results`,
    method: "POST",
    data: {
      parameters: parmeters,
      includeTableSchema: false,
      // maxRows: 100,
    },
  });

//returns: [{"country_id": 1, "country_name": "Brazil"}, {....}]
export const getCountries = () => getQueryResults(queryIDs.countries);
export const getSubnationalUnits = () =>
  getQueryResults(queryIDs.subnational_units);
export const getlocalGovernment = () =>
  getQueryResults(queryIDs.local_government_units);
export const getManagedAccessAreas = () =>
  getQueryResults(queryIDs.managed_access_areas);
export const getCommunities = (filters) =>
  getParametrizedQueryResults(queryIDs.communities, filters);
export const getCatchTotals = (filters) =>
  getParametrizedQueryResults(queryIDs.catch_totals_by_ma, filters);
export const getFishersTotalsBySex = (filters) =>
  getParametrizedQueryResults(queryIDs.total_fishers_male_female, filters);
export const getCatchCompositionTop10 = (filters) =>
  getParametrizedQueryResults(queryIDs.catch_composition_top10, filters);
export const getTotalCatchPerMonth = (filters) =>
  getParametrizedQueryResults(queryIDs.total_catch_per_month, filters);
export const getAvgCatchValueCPUE = (filters) =>
  getParametrizedQueryResults(queryIDs.avg_catch_value_cpue, filters);
export const getMedianLength = (filters) =>
getParametrizedQueryResults(queryIDs.median_length_catch, filters);
export const getExportData = (filters) => 
  getParametrizedQueryResults(queryIDs.export_catch, filters);
