import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef } from "react";
import { fetchCommunities } from "../redux/communitiesSlice";
import {
  avgCatchValueCPUESlice,
  catchCompositionTop10Slice,
  selectSpeciesFilters,
  totalCatchPerMonthSlice,
  medianLengthSlice
} from "../redux/chartSlice";
import { fetchTotals } from "../redux/totalsSlice";
import { selectMAIDsAndDateRangeFilters } from "../redux/selectors";
import { selectHandleFishBaseIDsChange } from "../redux/catchCompositionTop10SettingsSlice";

const useLoadData = (delay = 1000) => {
  const isLoaded = useRef(false);
  const setIsLoaded = () => {
    isLoaded.current = true;
  };

  const dispatch = useDispatch();
  const speciesFilters = useSelector(selectSpeciesFilters);
  const selectedMAIDsAndDateRangeFilters = useSelector(
    selectMAIDsAndDateRangeFilters
  );
  const handleFishBaseIDsChange = useSelector(selectHandleFishBaseIDsChange);

  //Dispatch Graph Fetch actions here
  //TODO: Implement Axios Cancel Token
  const loadGraphsData = useCallback(() => {
    return Promise.all([
      dispatch(fetchCommunities()),
      dispatch(catchCompositionTop10Slice.fetchData()),
      dispatch(totalCatchPerMonthSlice.fetchData()),
      dispatch(avgCatchValueCPUESlice.fetchData()),
      dispatch(medianLengthSlice.fetchData()),
      dispatch(fetchTotals()),
    ]);
  }, [dispatch]);

  const onFirstLoad = useCallback(async () => {
    await loadGraphsData();
    setIsLoaded();
  }, [loadGraphsData]);

  //Load all charts and communities with default settings for a first time.
  useEffect(() => {
    onFirstLoad();
  }, [onFirstLoad]);

  //Handle speciesFilters filters changes and load Catch Composition Top 10 chart data
  useEffect(() => {
    let timeout;
    if (isLoaded.current) {
      timeout = setTimeout(() => {
        clearTimeout(timeout);
        dispatch(catchCompositionTop10Slice.fetchData());
      }, delay);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [delay, dispatch, speciesFilters]);

  //Handle selectedMAIDsAndDateRangeFilters filters changes and Load Totals, Communities and charts data
  useEffect(() => {
    let timeout;
    if (isLoaded.current) {
      timeout = setTimeout(() => {
        clearTimeout(timeout);
        loadGraphsData();
      }, delay);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [delay, loadGraphsData, selectedMAIDsAndDateRangeFilters]);

  //Handle handleFishBaseIDsChange and load  Total Catch per Month, AvgCatchValueCPUE charts data
  useEffect(() => {
    let timeout;
    if (isLoaded.current && handleFishBaseIDsChange > 0) {
      timeout = setTimeout(() => {
        clearTimeout(timeout);
        dispatch(totalCatchPerMonthSlice.fetchData());
        dispatch(avgCatchValueCPUESlice.fetchData());
        dispatch(medianLengthSlice.fetchData());
      }, delay);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [delay, dispatch, handleFishBaseIDsChange]);
};

export default useLoadData;
