import MultiSelectDropdown from "./MultiSelectDropdown";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  countrySlice,
  snuSlice,
  lguSlice,
  maSlice,
} from "../../redux/filterSlice";

const SubnationalUnitsFilter = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(snuSlice.selectIsLoading);
  const allSubnationalUnits = useSelector(snuSlice.selectAll);
  const allCountriesEntities = useSelector(countrySlice.selectEntities);
  const selectedCountries = useSelector(countrySlice.selectSelectedIDs);
  // const groupBy = useMemo(
  //   () => (selectedCountries.length > 1 ? "country_name" : null),
  //   [selectedCountries]
  // );
  const options = useMemo(
    () =>
      allSubnationalUnits
        .filter(
          ({ country_id }) =>
            !selectedCountries.length || selectedCountries.includes(country_id)
        )
        .map((item) => ({
          country_name: allCountriesEntities[item.country_id]?.country_name,
          ...item,
        })),
    [allSubnationalUnits, allCountriesEntities, selectedCountries]
  );
  const selectedValues = useSelector(snuSlice.selectSelected);

  const onItemSelectionChange = (items, isSelected) => {
    dispatch(snuSlice.setSelected({ items, isSelected }));
    const payload = {
      key: "snu_id",
      parents: items,
      isSelected: isSelected,
    };
    dispatch(lguSlice.setSelectedByParent(payload));
    dispatch(maSlice.setSelectedByParent(payload));
  };
  return (
    <MultiSelectDropdown
      id={"subnationalUnits"}
      label={"Subnational Unit"}
      options={options}
      selectedValues={selectedValues}
      getOptionSelected={(option, value) => option.snu_id === value.snu_id}
      // groupBy={groupBy}
      onItemSelectionChange={onItemSelectionChange}
      displayValue={"snu_name"}
      loading={isLoading}
      closeOnSelect={false}
    />
  );
};
export default SubnationalUnitsFilter;
