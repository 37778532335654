import React from "react";
import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";

const StyledSlider = withStyles({
  root: {
    color: "#ccd1d1",
    height: 1,
    padding: "10px 0",
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -10,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  track: {
    height: 4,
    color: "#0065B3",
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
  },
})(Slider);

const RangeSlider = ({ value, onChange, onChangeCommitted, min, max }) => {
  const onSliderChange = (e, newValue) => onChange(newValue[0], newValue[1]);
  const onSliderChangeCommitted = (e, newValue) => onChangeCommitted();

  return (
    <StyledSlider
      step={86400000}
      value={value}
      onChange={onSliderChange}
      onChangeCommitted={onSliderChangeCommitted}
      min={min}
      max={max}
    />
  );
};

export default RangeSlider;
