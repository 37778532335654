import { createSelector } from "@reduxjs/toolkit";
import { selectIsFiltersLoading } from "./filterSlice";
import { selectIsLoading as selectIsCommunitiesLoading } from "./communitiesSlice";
import { selectIsChartsLoading } from "./chartSlice";
import { selectIsLoading as selectIsTotalsLoading } from "./totalsSlice";

export const selectIsDataLoading = createSelector(
  [
    selectIsFiltersLoading,
    selectIsCommunitiesLoading,
    selectIsChartsLoading,
    selectIsTotalsLoading,
  ],
  (...args) => args.some((arg) => !!arg)
);
