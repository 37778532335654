import CountriesFilter from "./CountriesFilter";
import SubnationalUnitsFilter from "./SubnationalUnitsFilter";
import LocalGovernmentFilter from "./LocalGovernmentFilter";
import ManagedAccessAreasFilter from "./ManagedAccessAreasFilter";
import React from "react";
import { withStyles } from "@material-ui/core/styles";

const Filter = withStyles({
  root: {
    marginBottom: "15px",
  },
})(({ classes, children }) => <div className={classes.root}>{children}</div>);

const LocationFilters = () => (
  <>
    <Filter>
      <CountriesFilter />
    </Filter>
    <Filter>
      <SubnationalUnitsFilter />
    </Filter>
    <Filter>
      <LocalGovernmentFilter />
    </Filter>
    <Filter>
      <ManagedAccessAreasFilter />
    </Filter>
  </>
);

export default LocationFilters;
