import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { withStyles } from "@material-ui/core/styles";
import {
  selectSpeciesGrouping,
  selectSpeciesNames,
  selectSpeciesRange,
  speciesGroupings,
  speciesNamings,
  speciesRanges,
  toggleSpeciesGrouping,
  toggleSpeciesNames,
  toggleSpeciesRange,
} from "../../redux/catchCompositionTop10SettingsSlice";

const SwitchButtonGroup = withStyles((theme) => ({
  root: {
    padding: "2px 0 5px 0",
  },
  grouped: {
    textTransform: "none",
    fontWeight: "normal",
  },
}))(ButtonGroup);
const SwitchButton = withStyles((theme) => ({
  root: {},
  containedPrimary: {
    backgroundColor: "#6C9EE9",
    "&:hover": { backgroundColor: "#6C9EE9" },
  },
  outlinedPrimary: {
    color: "#C4C4C4",
    borderColor: "#C4C4C4",
    "&:hover": {
      color: "#BAD3ED",
      backgroundColor: "#EBF5FF",
      borderColor: "#6C9EE9",
    },
  },
}))(Button);

const FilterItemSwitch = ({ values, checked, onChange }) => (
  <SwitchButtonGroup
    fullWidth
    disableElevation
    color="primary"
    size="small"
    aria-label="fullwidth button group"
  >
    {values.map(({ key, label }) => (
      <SwitchButton
        key={key}
        variant={key === checked ? "contained" : "outlined"}
        {...(key !== checked && { onClick: onChange })}
      >
        {label}
      </SwitchButton>
    ))}
  </SwitchButtonGroup>
);

const CatchCompositionFilters = () => {
  const dispatch = useDispatch();

  const speciesRange = useSelector(selectSpeciesRange);
  const speciesGrouping = useSelector(selectSpeciesGrouping);
  const speciesNames = useSelector(selectSpeciesNames);
  const onSpeciesRangeChange = () => dispatch(toggleSpeciesRange());
  const onSpeciesGroupingChange = () => dispatch(toggleSpeciesGrouping());
  const onSpeciesNamesChange = () => dispatch(toggleSpeciesNames());

  return (
    <Typography component="div">
      <FilterItemSwitch
        checked={speciesRange}
        onChange={onSpeciesRangeChange}
        name="speciesRange"
        values={[
          { key: speciesRanges.focal, label: "Focal Species" },
          { key: speciesRanges.all, label: "All Species" },
        ]}
      />
      <FilterItemSwitch
        checked={speciesGrouping}
        onChange={onSpeciesGroupingChange}
        name="speciesGrouping"
        values={[
          { key: speciesGroupings.family, label: "Family" },
          { key: speciesGroupings.species, label: "Species" },
        ]}
      />
      <FilterItemSwitch
        checked={speciesNames}
        onChange={onSpeciesNamesChange}
        name="speciesNames"
        values={[
          { key: speciesNamings.local, label: "Local Name" },
          { key: speciesNamings.scientific, label: "Scientific Name" },
        ]}
      />
    </Typography>
  );
};
export default CatchCompositionFilters;
