import {
  VictoryContainer,
  VictoryLegend,
  VictoryPie,
  VictorySharedEvents,
  VictoryTheme,
} from "victory";
import { useCallback, useEffect, useMemo, useState } from "react";
import { catchCompositionTop10Slice } from "../../redux/chartSlice";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  addFishBaseIDs,
  removeFishBaseIDs,
  selectFishBaseIDs,
  selectSpeciesNames,
  speciesNamings,
} from "../../redux/catchCompositionTop10SettingsSlice";

const colorScale = [
  "#A0E8E3",
  "#5CB9EA",
  "#F1E15B",
  "#F99852",
  "#3DCE8C",
  "#B28EF9",
  "#6084F4",
  "#F589E2",
  "#A7E7B1",
  "#D294A3",
];

const initialData = [
  {
    isLoading: true,
    local_name: "",
    scientific_name: "",
    total_weight_mt: 100,
  },
];

const labelColumn = {
  [speciesNamings.local]: "local_name",
  [speciesNamings.scientific]: "scientific_name",
};
const settingsByScreen = {
  lg: {
    width: 520,
    height: 365,
    pieRadius: 90,
    pieInnerRadius: 50,
    pieOrigin: { x: 520 / 2, y: 100 },
    legend: { itemsPerRow: 5, x: 0, y: 210 },
  },
  sm: {
    width: 520,
    height: 625,
    pieRadius: 120,
    pieInnerRadius: 70,
    pieOrigin: { x: 520 / 2, y: 130 },
    legend: { itemsPerRow: 10, x: 0, y: 270 },
  },
};

const CatchComposition = () => {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down(960));
  const settings = !isSM ? settingsByScreen.lg : settingsByScreen.sm;

  const dispatch = useDispatch();
  const [data, setData] = useState(initialData);
  const catchData = useSelector(catchCompositionTop10Slice.selectAll);
  const chartData = useMemo(
    () => (catchData.length ? catchData : initialData),
    [catchData]
  );
  const speciesNames = useSelector(selectSpeciesNames);
  const selectedFishBaseIDs = useSelector(selectFishBaseIDs);
  const selected = useMemo(
    () =>
      chartData.map(
        ({ fishbase_ids }) =>
          !!fishbase_ids?.every((id) => selectedFishBaseIDs.includes(id))
      ),
    [chartData, selectedFishBaseIDs]
  );

  //Handle redux data change and update chart data + reset selection to default
  useEffect(() => {
    setData(chartData);
  }, [chartData]);

  const labelField = useMemo(() => labelColumn[speciesNames], [speciesNames]);

  const onToggleSelection = useCallback(
    (key) => {
      const fishbase_ids = chartData[key]?.fishbase_ids;
      if (fishbase_ids) {
        if (!selected[key]) {
          // console.log("CatchComposition: addFishBaseIDs", fishbase_ids);
          dispatch(addFishBaseIDs(fishbase_ids));
        } else {
          // console.log("CatchComposition: removeFishBaseIDs", fishbase_ids);
          dispatch(removeFishBaseIDs(fishbase_ids));
        }
      }
    },
    [chartData, dispatch, selected]
  );

  const legendData = useMemo(
    () =>
      data.map(
        (
          { [labelField]: label, scientific_name, total_weight_mt, isLoading },
          index
        ) => ({
          name: !isLoading
            ? `${
                label || `-/- (${scientific_name})` || "Unknown"
              } - ${total_weight_mt?.toFixed(2)}`
            : "Loading...",
          symbol: {
            type: "square",
            fill: colorScale[index],
            size: 11,
            strokeWidth: 1,
            // stroke: ({ index }) =>
            //   selected[index] ? "#5CB9EA" : colorScale[index],
          },
          labels: {
            fill: ({ index }) =>
              selected[index]
                ? "#6C9EE9"
                : VictoryTheme.material.legend.style.labels.fill,
          },
        })
      ),
    [data, labelField, selected]
  );
  return (
    <VictoryContainer
      width={settings.width}
      height={settings.height}
      style={{ touchAction: "auto" }}
    >
      <VictorySharedEvents
        events={[
          {
            childName: ["legend", "pie_chart"],
            eventHandlers: {
              onClick: (event, props, key) => onToggleSelection(parseInt(key)),
              onMouseOver: () => {
                return [
                  {
                    childName: ["pie_chart"],
                    target: "data",
                    mutation: (props) => ({
                      style: { ...props.style, opacity: 0.6 },
                    }),
                  },
                  {
                    childName: ["legend"],
                    target: "data",
                    mutation: (props) => ({
                      style: {
                        ...props.style,
                        opacity: 0.6,
                        strokeWidth: 1,
                        stroke: selected[props.index]
                          ? "black"
                          : colorScale[props.index],
                      },
                    }),
                  },
                  {
                    childName: ["pie_chart", "legend"],
                    target: "labels",
                    mutation: (props) => ({
                      active: true,
                    }),
                  },
                ];
              },
              onMouseOut: () => {
                return [
                  {
                    childName: ["pie_chart", "legend"],
                    target: "data",
                    mutation: (props) => null,
                  },
                  {
                    childName: ["pie_chart", "legend"],
                    target: "labels",
                    mutation: (props) => null,
                  },
                ];
              },
            },
          },
        ]}
      >
        <VictoryPie
          animate={{ duration: 500 }}
          name={"pie_chart"}
          standalone={false}
          width={200}
          height={200}
          origin={settings.pieOrigin}
          radius={({ index }) =>
            settings.pieRadius + (!!selected[index] ? 10 : 0)
          }
          innerRadius={({ index }) =>
            settings.pieInnerRadius + (!!selected[index] ? 10 : 0)
          }
          cornerRadius={2}
          padAngle={1}
          style={{ data: { cursor: "pointer" } }}
          // labels={({ datum }) =>
          //   `${datum[labelField]}\nWeight Kg: ${datum.total_weight_mt}`
          // }
          labels={() => ""} //Remove labels
          colorScale={colorScale}
          data={data}
          x={labelField}
          y="total_weight_mt"
        />

        <VictoryLegend
          theme={VictoryTheme.material}
          name={"legend"}
          gutter={{ left: 0, right: isSM ? 0 : 15 }}
          rowGutter={{ top: 0, bottom: isSM ? 0 : 5 }}
          standalone={false}
          x={settings.legend.x}
          y={settings.legend.y}
          orientation="vertical"
          itemsPerRow={settings.legend.itemsPerRow}
          style={{
            labels: {
              cursor: "pointer",
              fontSize: isSM ? 21 : 14,
              lineHeight: isSM ? 0.55 : 0.7,
            },
          }}
          data={legendData}
        />
      </VictorySharedEvents>
    </VictoryContainer>
  );
};
export default CatchComposition;
