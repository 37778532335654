import React, { useEffect, useState } from "react";
import "./DateRange.css";
import { useMediaQuery } from "@material-ui/core";
import RangeSlider from "./RangeSlider";
import DatesPicker from "./DatePicker";
import {
  selectDateRange,
  setDateRange,
  setEndDate,
  setStartDate,
} from "../../redux/dateRangeFilterSlice";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

const startDate = new Date("2019-01-17");
startDate.setHours(0, 0, 0, 0);
const endDate = new Date();
endDate.setHours(0, 0, 0, 0);

const min = startDate.getTime();
const max = endDate.getTime();
const RangeContainer = withStyles(() => ({
  root: {
    position: "absolute",
    left: "25px",
    bottom: "20px",
    width: "50%",
    zIndex: 1002,
    borderRadius: "10px",
    boxShadow: "0px 3px 5px rgba(0,0,0,0.1)",
  },
}))(Paper);

const styles = {
  datePickerLabel: {
    textAlign: "center",
    fontSize: "14px",
    padding: "8px",
  },
};

const DateRange = () => {
  const dispatch = useDispatch();
  const dateRange = useSelector(selectDateRange);
  const [rangeValue, setRangeValue] = useState(dateRange);

  useEffect(() => {
    setRangeValue(dateRange);
  }, [dateRange]);

  const onRangeChange = (start, end) => setRangeValue({ start, end });
  const onChangeCommitted = () => dispatch(setDateRange(rangeValue));
  const onStartChange = (start) => {
    setRangeValue({ ...rangeValue, start: start.getTime() });
    dispatch(setStartDate(start.getTime()));
  };
  const onEndChange = (end) => {
    setRangeValue({ ...rangeValue, end: end.getTime() });
    dispatch(setEndDate(end.getTime()));
  };

  const isMobile = useMediaQuery("(max-width: 667px)");

  const startDatePicker = (
    <DatesPicker
      dateValue={rangeValue.start}
      onChange={onStartChange}
      minDate={min}
      maxDate={max}
    />
  );
  const endDatePicker = (
    <DatesPicker
      dateValue={rangeValue.end}
      onChange={onEndChange}
      minDate={min}
      maxDate={max}
    />
  );
  const rangeSlider = (
    <RangeSlider
      value={[rangeValue.start, rangeValue.end]}
      onChange={onRangeChange}
      onChangeCommitted={onChangeCommitted}
      min={min}
      max={max}
    />
  );
  return isMobile ? (
    <Grid container>
      <Grid container justify="space-between" spacing={3}>
        <Grid item xs={6}>
          <div style={styles.datePickerLabel}>From</div>
          {startDatePicker}
        </Grid>
        <Grid item xs={6}>
          <div style={styles.datePickerLabel}>To</div>
          {endDatePicker}
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 20 }}>
        {rangeSlider}
      </Grid>
    </Grid>
  ) : (
    <RangeContainer id="RangeContainer">
      <Grid container style={{ padding: 15 }} alignItems={"center"} spacing={2}>
        <Grid item xs={6} sm={12} md={7} style={{ padding: "0 20px" }}>
          {rangeSlider}
        </Grid>
        <Grid item xs={6} sm={12} md={5}>
          <Grid container justify="space-between" spacing={2}>
            <Grid item xs={6} sm={5} md={6}>
              {startDatePicker}
            </Grid>
            <Grid item xs={6} sm={5} md={6}>
              {endDatePicker}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RangeContainer>
  );
};

export default DateRange;
