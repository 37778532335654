import MultiSelectDropdown from "./MultiSelectDropdown";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lguSlice, maSlice } from "../../redux/filterSlice";

const ManagedAccessAreasFilter = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(maSlice.selectIsLoading);
  const allMA = useSelector(maSlice.selectAll);
  const allLGUEntities = useSelector(lguSlice.selectEntities);
  const selectedLGU = useSelector(lguSlice.selectSelectedIDs);
  const selectedValues = useSelector(maSlice.selectSelected);
  // const selectedIDs = useSelector(maSlice.selectSelectedIDs);
  // const groupBy = useMemo(() => (selectedLGU.length > 1 ? "lgu_name" : null), [
  //   selectedLGU,
  // ]);
  const options = useMemo(
    () =>
      allMA
        .filter(
          ({ lgu_id, ma_id }) =>
            !selectedLGU.length || selectedLGU.includes(lgu_id)
        )
        .map((item) => ({
          lgu_name: allLGUEntities[item.lgu_id]?.lgu_name,
          ...item,
        })),
    [allMA, selectedLGU, allLGUEntities]
  );

  const onItemSelectionChange = (items, isSelected) => {
    // dispatch(lguSlice.setSelected({ items, isSelected }));
    dispatch(maSlice.setSelected({ items, isSelected }));
  };

  return (
    <MultiSelectDropdown
      id={"managedAccessAreas"}
      label={"Managed Access Area"}
      options={options}
      selectedValues={selectedValues}
      getOptionSelected={(option, value) => option.ma_id === value.ma_id}
      // groupBy={groupBy}
      onItemSelectionChange={onItemSelectionChange}
      displayValue={"ma_name"}
      loading={isLoading}
      closeOnSelect={false}
    />
  );
};
export default ManagedAccessAreasFilter;
