import React from "react";
import Map from "../Map/Map";
import FiltersDrawer from "../Filters/FiltersDrawer";
import Info from "../Info/Info";
import "./Dashboard.css";
import Charts from "../Charts/Charts";
import DataLoadingProgress from "../Progress/DataLoadingProgress";

const MobileDashboard = () => (
  <div className={"DashboardContainer"}>
    <Map />
    <Charts />
    <Info />
    <FiltersDrawer />
    <DataLoadingProgress />
  </div>
);

export default MobileDashboard;
